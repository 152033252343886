import React, { useState,useRef } from 'react';
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"
import Button from "../components/styles/Button"

export const pageQuery = graphql`
 query { 
    allStrapiMediaCategories {
        edges {
            node {
                title,
                url
            }
        }
    },
    allStrapiDownload {
        edges {
            node {
                seo {
                    metaTitle
                    metaDescription
                    shareImage {
                    url
                    }
                },
                download {
                    title
                    info
                    url
                    picture {
                        localFile {
                            childImageSharp {
                            gatsbyImageData(width: 1890, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                            }
                        }
                    }
                }
        }
     }
   } 
 }
`

export default function Download({location,data}) {
    const [headerColor,setHeaderColor] = useState("bg-white text-black")
    const node = data.allStrapiDownload.edges[0].node
    const list = data.allStrapiMediaCategories.edges
    const refList = useRef(null)

    const showMore = (e)=>{
        let listDom = refList.current.querySelectorAll(".hidden")
        const listCount = 6
        for(let i=0;i<listDom.length;i++){
            if(i<listCount){
                listDom[i].classList.remove("hidden")
            }
        }
        if(listDom.length<=listCount){
            e.target.classList.add("hidden")
        }
    }

    return (
        <Layout bgColor={'bg-white'} footer={true} headerColor={headerColor}>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'sotto',
              }}
            />
            
            <div className="container mx-auto pt-[193px] pb-[140px] sm:pt-24 sm:pb-10">
                <div className="flex justify-between items-end pb-[88px] sm:flex-col sm:items-start sm:pb-10">
                    <h1 className="text-[40px] leading-snug sm:mb-8">媒体中心</h1>
                    <ul className="flex items-center text-gray flex-wrap sm:justify-center sm:w-full">
                    <li className="mr-[38px] sm:mr-5"><a className="hover:text-black" href="/media">全部</a></li>
                    {
                        list.map((n,i)=>{
                            return (
                                <li key={i} className='mr-[38px] sm:mr-5'><a className={`hover:text-black`} href={n.node.url}>{n.node.title}</a></li>
                            )
                        })
                    }
                    <li><a className="text-black" href="/download">资料下载</a></li>
                    </ul>
                </div>
                <div ref={refList}>
                {
                    node.download.map((n,i)=>{
                        return (
                        <div className={`flex justify-between items-start pb-[100px] sm:pb-20 xl:flex-col-reverse ${i<6?'':'hidden'}`} key={i}>
                            <div className="mr-115px w-[380px] xl:w-full xl:mr-0">
                                <h2 className="text-3xl leading-snug pb-[60px] sm:pb-10">{n.title}</h2>
                                <p className="pb-10">{n.info}</p>
                                <Button href={n.url} variant="black">立即下载</Button>
                            </div>
                            <figure className="overflow-hidden w-[945px] xl:w-full xl:mb-2.5"><GatsbyImage className="w-full" image={getImage(n.picture.localFile)} alt="banner"/></figure>
                        </div>)
                    })
                }
                </div>
                
                
                
                <div onClick={showMore} className={`w-[240px] h-10 rounded-full bg-[#ededed] hover:bg-black hover:text-white flex justify-center items-center mx-auto mt-[82px] hover:cursor-pointer ${node.download.length>6?'':'hidden'}`}>显示接下来6个</div>

            </div>
        </Layout>
    )
}

